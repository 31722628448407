.row {
	display: flex;
	flex-direction: row;
}

.column {
	flex: 1 1 0px;
	padding: 2em;
}

.step-description {
	padding: 1em 0em 0em 0em;
}

.action-title {
	margin-bottom: 2em;
}

.action-link {
	margin: 2em;
}

.button-div {
	margin-top: 2em;
}

.link-panel {
	cursor: pointer;
}
