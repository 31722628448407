@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.App {
	flex-grow: 1;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.no-padding {
	padding: 0 !important;
}
.no-margin {
	margin: 0 !important;
}
.cursor-pointer {
	cursor: pointer;
}
.border-none {
	border: none;
}
.position-relative {
	position: relative;
}
.centered-text {
	text-align: center;
}
.content {
	flex: 1;
}
.edit-modal {
	/*width: 50%;
 */
	max-width: 768px;
}
.edit-modal .modal-header {
	border: none;
}
.edit-modal .modal-content {
	border-radius: 5px;
	padding: 10px 15px;
}
.action-modal .modal-title {
	font-weight: 500;
}
.action-modal {
	width: 50%;
	max-width: 100%;
}
.action-modal .modal-header {
	border: none;
}
.action-modal .modal-content {
	box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.27);
	border-radius: 5px;
	padding: 10px 15px;
	text-align: center;
}
.action-modal .modal-title {
	font-weight: 500;
}
.form-group {
	display: inline-block;
}
.form-control {
	border: 1px solid #d1d3e2;
	padding: 0 10px;
	border-radius: 3px;
	margin-right: 0px;
}
.form-group label {
	font-size: 12px;
	text-transform: uppercase;
	color: gray;
	font-weight: 500;
}
.form-group label.role {
	font-size: 18px;
}
.edit-modal .custom-radio .custom-control-label::before {
	border-radius: 25%;
}
.edit-modal button {
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 25px;
}
.edit-modal button.discard {
	background: white;
	color: #2c4251;
}
.modal-header > button {
	font-size: 32px;
}
/* Styles for tables (matrix views) */
.conserv-table {
	background: #fff;
	border: none;
	font-size: 14px;
}
.conserv-table th {
	text-transform: uppercase;
	border-bottom: 2px solid #e3e6f0;
}
.conserv-table th:first-child {
	color: #2c4251;
}
.conserv-table td:first-child {
	font-size: 16px;
}
.conserv-table td:first-child .small {
	color: gray;
	text-transform: uppercase;
	font-size: 50%;
}
.conserv-table th,
.conserv-table td {
	border-top: none;
	padding: 1em;
	vertical-align: middle;
}
.conserv-table tr {
	border-bottom: 2px solid #eaecf4;
}
.conserv-table td {
	color: #2c4251;
	font-weight: 500;
}
.conserv-table .dropdown-menu {
	top: -10px !important;
	left: -60px !important;
	padding: 0 !important;
}
.conserv-table .dropdown-item {
	border-bottom: 1px solid #eee !important;
	padding: 10px 10px !important;
}
.conserv-table .dropdown-item:focus {
	color: #2c4251 !important;
	background: none;
}
.conserv-table .dropdown-toggle {
	background: none !important;
	border: none !important;
	color: #2c4251 !important;
}
.conserv-table .dropdown-toggle::after {
	display: none !important;
}
.horizontal-separator {
	width: 100%;
	border: 1px solid #eee;
	margin: 25px 0;
	padding: 0 20px;
}
.conserv-button {
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 25px;
	margin: 0rem 0.5rem;
}
.Login .form-group {
	width: 100%;
}
@media all and (min-width: 480px) {
	.Login {
		padding: 60px;
	}
	.Login form {
		margin-top: 1em;
	}
}
.Login {
	padding: 1em;
	margin-top: 5em;
	text-align: center;
}
.new-link {
	padding-top: 1em;
	float: left;
}
.help-link {
	padding-top: 0.4em;
	float: right;
}
#help-links-container {
	height: 4em;
}
.password-reset-form .form-group {
	width: 100%;
}
.actions-button {
	background: none;
	border: none;
	color: #2c4251;
	outline: none;
}
.delete-item,
.delete-item:hover {
	color: #e74a3b;
}
.full-width {
	width: 100%;
}
/* Classes used in the top navigation component */
.nav-search {
	margin-right: 1em;
}
.nav-message {
	margin-right: 1em;
}
/* Classes used in search functions, including those in the app header */
.search-overlay {
	margin-top: 20px;
	z-index: 100;
	border: 1px solid #eee;
	border-radius: 4;
	background-color: #f2f2f2;
	padding: 10px;
	color: #000;
	border-radius: 3;
	left: -10px !important;
}
.annotation-button {
	margin-right: 1em;
}
.link {
	color: #0000ee;
	text-decoration: underline;
}
/* Datetime picker components */
.rdt {
	/* position: relative;
  */
	margin-right: 0;
	margin: 0;
	width: 100%;
}
.dow {
	font-size: 0.5rem;
	font-weight: 300;
	text-transform: uppercase;
}
.rdtArrows,
.rdtArrows span {
	font-weight: 300;
	width: 20px !important;
}
.calHeader {
	width: 100%;
	color: #555;
	font-weight: 600;
	text-align: left !important;
	padding-left: 10px;
	font-size: 0.8rem;
}
.rdt .form-control {
	width: 100%;
}
.rdtPicker {
	display: none;
	position: absolute;
	width: 206px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}
.rdtPicker .rdtTimeToggle {
	text-align: center;
}
.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.float-clear {
	clear: both;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #089cad;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtDay {
	width: 28px !important;
	font-weight: 300;
	font-size: 0.9rem;
	margin: 0 10px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #089cad;
	color: #fff;
	border-radius: 50%;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker td span.rdtOld {
	color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
	font-family: 'Roboto', 'Helvetica Neue', 'Open Sans', sans-serif !important;
	font-weight: 300;
	font-size: 1.1rem;
	text-align: left !important;
	padding-left: 10px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
}
.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Chrome/Safari/Opera */
	-khtml-user-select: none;
	/* Konqueror */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}
.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}
.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}
.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}
.rdtPicker thead button {
	width: 100%;
	height: 100%;
}
td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}
.rdtCounters {
	display: inline-block;
}
.rdtCounters > div {
	float: left;
}
.rdtCounter {
	height: 100px;
}
.rdtCounter {
	width: 40px;
}
.rdtCounterSeparator {
	line-height: 105px !important;
}
.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Chrome/Safari/Opera */
	-khtml-user-select: none;
	/* Konqueror */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}
.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}
.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}
.rdtTime td {
	cursor: default;
}
.report iframe {
	box-shadow: 0 3px 3px #eee;
}
.ReactPasswordStrength {
	border: 1px solid #d1d3e2 !important;
	border-radius: 3px;
}
.ReactPasswordStrength-input {
	font-size: 1rem !important;
	font-weight: 400;
	width: 100% !important;
}
.ReactPasswordStrength-strength-desc {
	color: transparent;
	font-style: italic;
	text-overflow: ellipsis;
	overflow: hidden;
	position: absolute;
	right: 5px;
	bottom: 5px;
	text-align: right;
	transition: color 250ms ease-in-out;
	font-size: 12px;
	width: 25% !important;
}
.upper-container-right-heading {
	float: right;
	width: 100%;
	text-align: right;
}
.button-import {
	margin-right: 20px;
	font-weight: 400;
}
/* Expanding search bar */
.search-wrapper {
	display: inline;
	white-space: nowrap;
}
.search-box {
	position: relative;
	right: 0;
	display: inline;
	max-width: 500px;
	float: right;
	white-space: nowrap;
	transition: all 500ms ease-in-out;
}
/* Footer */
#Footer {
	padding: 30px 15%;
	border-top: 1px solid #eee;
	background-color: #ffffff;
	height: 15vh;
}
.app-body a {
	color: #089cad;
}
.app-body a:hover {
	color: #00697a;
}
.navbar a {
	color: #343a40;
}
.navbar a:hover {
	color: #00697a;
}
#Footer a {
	color: #343a40;
}
#Footer a:hover {
	color: #00697a;
}
#login-hero {
	height: 100%;
	float: right;
}
#login-hero-container {
	height: 100vh;
	margin: 0;
	padding: 0;
	overflow: auto;
}
#login-logo {
	margin-bottom: 5em;
	width: 80%;
}
#login-container {
	max-width: 320px;
	display: inline-block;
}
.login-tab-content {
	text-align: left;
}
.app-component-body {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.rating {
	margin: 0 !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.glyphicon-button {
	color: rgb(44, 66, 89);
	background: transparent;
	border: none;
}
.upper-container {
	margin: 40px 20px 20px 20px;
	display: inline-block;
	width: 95%;
}
.tab-content .upper-container {
	margin: 0px 20px 20px 20px;
}
.upper-container h5,
.upper-container button {
	margin-left: 10px;
	font-weight: inherit;
}
.top-upper-container {
	margin-top: 50px;
	margin-bottom: 0;
	color: #555;
	font-size: 0.7em;
	font-weight: 500;
}
.top-upper-container span {
	margin-left: 10px;
}
.bottom-upper-container {
	margin-top: 0;
}
.table-container {
	background: white;
	border-radius: 5px;
}
.invite-members {
	border-radius: 4px;
	font-size: 14px;
	padding: 5px 25px;
	margin-left: 10px;
}
.td-pad1 {
	padding: 1.5rem;
}
.alert-param-display {
	display: inline;
	margin-left: 5px;
}
.live-indicator {
	width: 2em;
}
.org-nav {
	margin-top: 50px;
}
.dropzone-container {
	width: 100%;
	text-align: center;
}
.dropzone {
	border: none;
}
.dropzone-label {
	margin-top: 2em;
	margin-bottom: 2em;
}
.file-preview-container {
	text-align: center;
	float: left;
	width: auto;
}
.import-container {
	margin: 2em;
}
.import-settings-container {
	width: 100%;
	overflow: auto;
	margin: 2em;
}
.steps-container {
	margin: 2em;
}
.summary-container {
	text-align: center;
	margin: 2em;
	width: 100%;
}
.full-width-column {
	width: 100%;
}
.center-glyph-container {
	height: 130px;
	text-align: center;
}
.summary-table {
	background: white;
	border: none;
	font-size: 14px;
}
.summary-table th {
	text-transform: uppercase;
	border-bottom: 2px solid #e3e6f0;
}
.summary-table th,
.summary-table td {
	border-top: none;
	padding: 1em;
	vertical-align: middle;
}
.summary-table tr {
	border-bottom: 2px solid #eaecf4;
}
.summary-table td {
	color: #2c4251;
	font-weight: 500;
}
.card-deck-subtitle {
	margin: 3rem;
}
.card-title .form-group {
	margin-top: 0.75rem;
	margin-bottom: 0;
}
.nested-card-deck {
	margin: 2rem;
}
.main-card {
	margin-bottom: 3em;
}
.main-card-progress-container {
	margin-top: 3rem;
	margin-left: 20%;
	margin-right: 20%;
}
.number-circle-selected {
	border-radius: 50%;
	width: 24px;
	height: 24px;
	padding: 2px;
	background: #fff;
	border: 2px solid #089cad;
	color: #089cad;
	text-align: center;
}
.number-circle {
	border-radius: 50%;
	width: 24px;
	height: 24px;
	padding: 2px;
	background: #fff;
	border: 2px solid #8792a1;
	color: #8792a1;
	text-align: center;
}
.alert-modal-title-icon {
	float: left;
	padding-right: 1em;
	padding-top: 2px;
}
.profile-datepicker input,
.timezone input {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #6e707e;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2 !important;
	padding: 0 10px;
	border-radius: 3px;
	-webkit-transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-box-shadow 0.15s ease-in-out;
}
label[type='checkbox'] {
	text-transform: capitalize;
}
/* .field-validated{
}
*/
.select-live-indicator {
	height: 100%;
	width: 30px;
	height: 22px;
}
.select-indicator-container {
	display: block;
	float: left;
	margin: 8px;
	overflow: none;
}
.select-value-container {
	display: block;
}
.select-option-container {
	width: 100%;
	overflow: hidden;
}
.select-table-label {
	margin-top: 0.5em;
}
.footer-links {
	margin-left: 10%;
	margin-right: 10%;
}
.graph-data-tooltip {
	background-color: white;
	padding: 0.5em;
	border: 1px solid;
	border-radius: 4px;
	border-color: rgb(49, 130, 189);
}
.third-party-auth-container {
	text-align: center;
	margin: 2em;
}
.third-party-auth-container div:first-of-type {
	display: inline-block;
}
.no-nav-form-container {
	margin-top: 3em;
}
.form-field-with-icon {
	width: 80%;
	margin-right: 1em;
}
.form-field-with-button {
	width: 60%;
	margin-right: 1em;
}
.social-button {
	border-radius: 4px;
	background-color: #eaecf4 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	padding-right: 1em !important;
	margin-right: 1em;
	margin-bottom: 1em;
}
.social-button-label {
	display: inline;
	padding: 0em 0em 0em 1em;
}
.form-label {
	font-size: 12px;
	text-transform: uppercase;
	color: gray;
	font-weight: 500;
}
.md-glyph-circle {
	border-radius: 50%;
	width: 48px;
	height: 48px;
	padding: 8px;
	background: #fff;
	border: 2px solid #8792a1;
	color: #8792a1;
	text-align: center;
}
.margin-auto {
	margin: auto;
}
.drawer {
	position: fixed;
	top: 0;
	z-index: 9999;
	transition: width 0s ease 0.3s, height 0s ease 0.3s,
		transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
	/*background: #000;
 */
	opacity: 0;
	width: 100%;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		height 0s ease 0.3s;
}
.drawer-content-wrapper {
	position: absolute;
	background: #fff;
	margin-top: 72px;
}

.drawer-content {
	overflow: auto;
	z-index: 1;
	position: relative;
	height: calc(100vh - 72px - 72px);
}

.drawer-handle {
	position: absolute;
	top: 72px;
	width: 41px;
	height: 40px;
	cursor: pointer;
	z-index: 0;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
}
.drawer-handle-icon {
	width: 14px;
	height: 2px;
	background: #333;
	position: relative;
	transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
	content: '';
	display: block;
	position: absolute;
	background: #333;
	width: 100%;
	height: 2px;
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
	top: -5px;
}
.drawer-handle-icon:after {
	top: 5px;
}
.drawer-left,
.drawer-right {
	width: 0%;
	height: 100vh;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
	height: 100vh;
}

.drawer-body {
	height: calc(100vh - 72px - 72px);
}

.drawer-scroll {
	/* height: 100vh; */
	padding: 0 !important;
}

.drawer-left.drawer-open,
.drawer-right.drawer-open {
	width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
	width: 0%;
}
.drawer-left {
	left: 0;
}
.drawer-left .drawer-handle {
	right: -40px;
	box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
	border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
	box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
	right: 0;
}
.drawer-right .drawer-content-wrapper {
	right: 0;
}
.drawer-right .drawer-handle {
	left: -40px;
	box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
	border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
	box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
	right: 1px;
	transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
	width: 100%;
	height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
	width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
	height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
	height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
	height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
	left: 50%;
	margin-left: -20px;
}
.drawer-top {
	top: 0;
}
.drawer-top .drawer-handle {
	top: auto;
	bottom: -40px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
	bottom: 0;
}
.drawer-bottom .drawer-content-wrapper {
	bottom: 0;
}
.drawer-bottom .drawer-handle {
	top: -40px;
	box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
	box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
	bottom: 1px;
	transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
	opacity: 0.3;
	height: 100%;
	transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
	background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
	transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
	transform: translateY(-5px) rotate(-45deg);
}
.drawer-title-text {
	display: inline;
	margin-left: 1em;
}
.drawer-title-container {
	padding-top: 0.75rem;
}
.drawer-subtitle {
	padding-bottom: 0.75rem;
}
.level-detail-header {
	margin: 20px;
}
.level-detail-element {
	display: inline-block;
	margin-left: 1rem;
	vertical-align: top;
}
.level-select-element {
	display: inline-block;
	padding: 0rem 0.5rem;
	width: 7rem;
}
.level-select-container {
	display: inline-block;
}
.funny-quote {
	text-align: center;
}
tfoot button {
	margin-right: 1rem;
}
.accordion-row {
	padding: 0px !important;
	border-bottom: 0px !important;
}
.accordion-content {
	padding: 1em;
}
.table-row-favorite {
	float: left;
	margin-right: 0.5rem;
}
.table-row-grouped {
	display: inline-block;
}
.level-table td {
	vertical-align: middle;
}
.graph-header-control {
	float: right;
}
.graph-header-switch {
	display: inline-block;
	margin-right: 1rem;
}
.small-switch {
	vertical-align: middle;
	margin-left: 0.5rem;
}
.graph-data-tooltip-datetime {
	font-size: 16px;
	color: gray;
}
.graph-data-tooltip-value {
	font-size: 14px;
	color: gray;
}
.graph-data-observation {
	font-size: 14px;
	color: gray;
}
.graph-data-action {
	font-size: 14px;
	color: gray;
}
.space-stat-label {
	display: inline;
	vertical-align: super;
	margin-left: 1em;
}
.scrollable-table {
	overflow: scroll;
	height: 200px;
}
.sticky-header {
	position: sticky;
}
.small-loading-container {
	height: 200px;
}
.tooltip-trigger {
	cursor: pointer;
	display: inline-block;
}
.form-group button {
	margin-right: 1rem;
}
.table-value-with-icon {
	display: inline-block;
	margin-right: 1rem;
}
.account-badge {
	display: inline-block;
	max-width: 20rem;
	white-space: normal !important;
	width: 100%;
	clear: both;
	font-weight: 400;
	color: #3a3b45;
	text-align: inherit;
	cursor: pointer;
}
.badge-toggle {
	padding: 0.25rem 1.5rem;
}
.form-group i {
	margin-left: 0.5rem;
}
.filter-header {
	width: 100%;
	display: inline-block;
}
.comment-container {
	margin-bottom: 1rem;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.comment-content {
	display: inline-block;
	margin-left: 1rem;
	max-width: 75%;
}
.comment-avatar {
	vertical-align: top;
	display: inline-block;
}
.comment-tools {
	float: right;
}
.comment-tools .dropdown-toggle {
	background: none !important;
	border: none !important;
	color: #2c4251 !important;
}
.comment-tools .dropdown-toggle::after {
	display: none !important;
}
