.App .navbar-brand {
	font-weight: bold;
	padding-left: 5px;
}

.navbar-brand {
	padding: 0px;
}

.navbar-nav .divider {
	margin-right: 15px;
}
.navbar-nav .sb-avatar {
	margin-top: 7.5px;
}

.conserv-navbar {
	background: white;
	-webkit-box-shadow: 0 3px 3px #eee;
	-moz-box-shadow: 0 3px 3px #eee;
	box-shadow: 0 3px 3px #eee;
}

.App .logo-image {
	max-width: 55px;
	width: 100%;
	padding: 5px;
}

.inline-link {
	display: inline;
	padding: 0;
}

.copyright {
	text-align: center;
	padding: 1em;
}

.divider {
	height: 40px;
	border: 0.5px solid #eee;
}

.rounded-circle {
	max-width: 40px;
	max-height: 40px;
	margin: 0 15px;
}

.section_name {
	margin: 0;
	color: #001b2d;
}

.dropdown-toggle.nav-link {
	color: #001b2d;
}

.dropdown-menu {
	right: 0;
	left: auto;
}

.center-text {
	text-align: center;
}

.glyphicon-button {
	color: rgb(44, 66, 89);
	background: transparent;
	border: none;
}

.font-bold {
	font-weight: bold;
}

.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

.right-align {
	text-align: right;
}

.left-align {
	text-align: left;
}

.center-align {
	text-align: center;
}

.no-pads {
	padding: 0 !important;
}

.mobile-badge {
	text-align: center;
	margin: 1em;
	display: inline;
}

.mobile-container {
	text-align: center;
	padding: 1em;
}

.google-play-image {
	height: 60px;
}

.apple-image {
	margin: 10px 10px 10px 25px;
}

.smallpads {
	padding: 5px;
}

.sb-avatar__text {
	border-radius: 50%;
	background-color: #000;
}

.color-underlay-full-w .sb-avatar,
.color-underlay-full-w .sb-avatar__image {
	width: auto !important;
}

.color-underlay-full-h .sb-avatar,
.color-underlay-full-h .sb-avatar__image {
	height: auto !important;
}

.org-logo-row {
	margin-left: 5px;
}

.change-av {
	font-size: 10px;
	text-transform: uppercase;
	color: #333;
	/* line-height: 120%; */
	font-weight: 600;
}

.change-av::before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-top: 0.3em;
	/* margin-right: -0.25em; Adjusts for spacing */
}

.no-av {
	font-size: 10px;
	text-transform: uppercase;
	color: #333;
	line-height: 100px;
	font-weight: 600;
}

.avatar-div-square {
	text-align: center;
	display: inline-block;
	height: 100px;
	width: 100px;
	position: relative;
}

.color-underlay-square {
	z-index: 8;
}

.color-overlay-square {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background-color: rgba(235, 235, 235, 0.7);
	z-index: 10;
	border-radius: 50%;
}

.avatar-div-full {
	text-align: center;
	display: inline-block;
	/* height: 100px; */
	min-width: 100px;
	position: relative;
}

.color-underlay-full {
	z-index: 8;
}

.color-overlay-full {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background-color: rgba(235, 235, 235, 0.7);
	z-index: 10;
	border-radius: 4px;
}

.profile-avatar {
	margin: 0 0 30px;
}

.logo-header-square {
	width: 100%;
	/* text-align: center; */
	margin-left: 6px;
	font-size: 12px;
	text-transform: uppercase;
	color: gray;
	font-weight: 500;
	margin-bottom: 10px;
}

.logo-header-full {
	width: 100%;
	/* text-align: center; */
	margin-left: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: gray;
	font-weight: 500;
	margin-bottom: 10px;
}

.separator-padding {
	padding: 0 0.75rem;
	border: 1px solid #fff;
}

/* */

.sb-avatar__text-org {
	border-radius: 4px;
	/* background-color: #000 */
}

.color-overlay-square-org {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background-color: rgba(235, 235, 235, 0.7);
	z-index: 10;
	border-radius: 4px;
}

.color-underlay-square-org,
.no-av-div {
	z-index: 8;
}

.no-av-div {
	background-color: rgba(235, 235, 235, 0.5);
}

.profile-form input {
	color: #6e707e !important;
}

.profile-form .react-phone-number-input__input {
	border: 0;
}

.profile-form .react-phone-number-input__row {
	height: 100%;
}

.what-is-happening {
	top: 0;
}

.profile-form .form-group {
	display: inline-grid;
}

.menu-lock {
	float: right;
	text-align: right;
}

.subscription-lock i {
	font-size: inherit !important;
}

.wrappable {
	padding: 0 2px;
}

.icon-lock {
	font-size: 10px;
	margin-left: -15px;
	margin-top: 10px;
}

.credits-total {
	font-size: 1rem;
	color: #1c0b1a;
}

.icon-lock-title span {
	/* font-size: 10px; */
	margin-left: 20px;
	margin-top: 6px;
	/* padding-right: 0px; */
}

.icon-lock-title {
	margin-top: 6px;
	padding-right: 8px;
	padding-left: 8px;
}

.credit-item {
	color: #858796;
}

.plan-col {
	border: 1px solid #ddd !important;
	border-radius: 4px;
	padding: 0;
	margin: 50px 10px;
}

.plan-body small {
	font-size: 0.7em;
	padding-bottom: 10px;
}

.plan-body h2 {
	/* margin-bottom: 0; */
	color: #2c4251;
	font-weight: 300;
}

.plan-body {
	padding: 10px 20px 20px;
	text-align: center;
}

.your-plan {
	background-color: #239ea7;
	border: 0.5 solid #666;
	color: #fff;
	font-size: 0.8em;
	text-align: center;
	padding: 5px;
}

.not-your-plan {
	border: 0.5 solid #666;
	color: #fff;
	font-size: 0.8em;
	text-align: center;
	padding: 5px;
}

.plan-items {
	font-size: 0.9em;
}

.subscription-call {
	text-align: center;
}

.bottom-slider {
	width: 500px;
	margin-left: 50px;
	margin-top: -10px;
}

.bottom-slider .MuiSlider-thumb {
	width: 1px !important;
	height: 322px !important;
	display: flex !important;
	outline: 0 !important;
	position: absolute !important;
	box-sizing: border-box !important;
	margin-top: -322px !important;
	margin-left: 0px !important;
	transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
	align-items: center !important;
	border-radius: 0 !important;
	justify-content: center !important;
	background-color: #aaa !important;
}
.side-slider {
	height: 320px;
	margin-bottom: -320px;
	margin-left: 32px;
}

.MuiSlider-vertical {
	z-index: 1;
}

.MuiSlider-valueLabel {
	z-index: 300 !important;
}

.side-slider .MuiSlider-thumb {
	width: 504px !important;
	height: 1px;
	display: flex;
	outline: 0;
	position: absolute;
	box-sizing: border-box;
	margin-top: 0;
	transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	align-items: center;
	margin-left: 0px !important;
	border-radius: 0%;
	justify-content: center;
	background-color: #aaa;
	margin-bottom: 0 !important;
}

.no-hr {
	border-bottom: 0 !important;
}

.aod-selector {
	margin-left: 50px;
	width: 200px;
}

.dot {
	border: 1px solid rgba(161, 164, 167, 0.8);
	height: 15px;
	width: 15px;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	margin-top: 4px;
}

.dots-container {
	position: absolute;
	display: inline-block;
}

.dot-demo {
	border: 1px solid rgba(161, 164, 167, 0.8);
	height: 11px;
	width: 11px;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	top: 5;
	margin: 4px 1px;
	/* float: left; */
	display: inline-block;
}

.dot-info {
	position: absolute;
	z-index: 5500 !important;
	background-color: #fff;
	height: 80px;
	width: 150px;
	border: 1px solid #ccc;
	border-radius: 4px;
	visibility: hidden;
	font-size: 0.8rem;
	padding: 0 5px;
	text-transform: capitalize;
}

.info-header {
	border-bottom: 1px solid #ccc;
	width: 100%;
	font-weight: 600;
	text-transform: capitalize;
}

.dot:hover + .dot-info {
	visibility: visible;
}

.red-dot {
	background-color: rgb(231, 74, 59);
}

.yellow-dot {
	background-color: rgb(246, 194, 62);
}

.orange-dot {
	background-color: rgb(246, 194, 62);
}

.green-dot {
	background-color: rgb(28, 200, 138);
}

.dot:hover {
	margin-top: 0px;
	margin-left: -3.5px;
	padding: 10px;
}

.chart-backdrop {
	background-color: #eee;
	border: 1px solid #ddd;
	height: 320px;
	width: 500px;
	position: absolute;
	top: 20px;
	left: 50px;
	z-index: 0;
}

.data-row {
	position: absolute;
	width: 600px;
	font-size: 0.6rem;
	left: 50px;
	margin-left: -25px;
	margin-top: 10px;
	text-align: center;
	font-weight: 300;
	text-transform: uppercase;
}

.data-row span {
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	padding-top: 3px;
	font-size: 0.8rem;
	font-weight: 400;
	margin-top: 5px;
}

.data-col {
	position: absolute;
	width: 20px;
	font-size: 0.6rem;
	left: 50px;
	margin-left: -28px;
	margin-top: -7px;
	text-align: center;
	font-weight: 300;
}

.variation {
	text-transform: uppercase;
	padding-top: 3px;
	font-size: 0.8rem;
	font-weight: 400;
	position: absolute;
	top: 340px;
	left: -10px;
	width: 320px;
	text-align: center;
	background: none;
	transform-origin: 0 0;
	transform: rotate(-90deg);
}

.percentage-2 {
	margin-top: 24px;
}

.percentage-3 {
	margin-top: -48px;
}

.aod-selector-2 {
	visibility: hidden;
}

.css-1pcexqc-container span {
	z-index: 8000;
}

.c-green {
	color: rgb(28, 200, 138);
}

.c-yellow {
	color: rgb(246, 194, 62);
}

.c-red {
	color: rgb(231, 74, 59);
}

.c-grey {
	color: #858796;
}

.sub-heads {
	/* text-transform: uppercase ; */
	/* font-size: 1rem; */
	/* font-weight: 600; */
}

.sub-deets h5 {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 600;
	color: #858796;
}

.sensor-header {
	margin-top: 12px;
	margin-left: 50px;
}

.sensor-header h5 {
	font-weight: 400;
	text-transform: uppercase;
	color: #858796;
}

.import-scroll {
	height: 60vh;
	overflow-y: scroll;
}

.import-width {
	width: 100%;
}

.task-card {
	margin: 10px;
	max-width: calc(25% - 20px);
	border-radius: 4px;
	position: relative;
	padding-bottom: 70px;
	min-height: 400px;
}

.task-dropdown .dropdown-toggle {
	background: none !important;
	border: none !important;
	color: #2c4251 !important;
}

.task-dropdown .dropdown-toggle::after {
	display: none !important;
}

.task-checks label {
	font-size: 0.8rem;
}

.small-text {
	font-size: 0.8rem !important;
}

.task-dates {
	position: absolute;
	bottom: 0;
	padding-bottom: 25px;
	color: #858796;
}

.task-status {
	font-size: 1rem !important;
	color: #858796;
	margin-bottom: 0px;
	/* font-weight: 600 !important; */
}

.task-card hr {
	margin: 10px 0;
}

.incomplete-task {
	color: rgb(246, 194, 62);
}

.complete-task {
	color: rgb(28, 200, 138);
}

.overdue-task {
	color: rgb(231, 74, 59);
}
.levels-container {
	width: 100%;
	padding: auto;
}

.button-margin {
	margin-top: 30px;
}

.task-sort-list {
	margin-top: -33px;
}

.task-list {
	/* margin: 10px; */
	padding: 0px;
	border-radius: 4px;
	position: relative;
	/* padding-bottom: 70px; */
	/* min-height: 400px; */
	/*height: 300px;*/
	overflow: auto;
}

.MuiListItem-button {
	border-top: 0.5px solid #fff !important;
	border-bottom: 0.5px solid #eee !important;
}

.list-header {
	margin-top: 30px;
}

.MuiDataGrid-menuIcon {
	margin-right: 0px !important;
	button {
		padding: 5px !important;
	}
}